#webchat-root {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  font-family: 'Open Sans', sans-serif;
}

.Twilio-MainHeader,
.Twilio-ChatItem-Avatar,
.Twilio-MessageBubble,
.Twilio-MessageInput-SendButton,
.Twilio-MessageCanvasTray {
  background: #902d8d !important;
}

.Twilio-MessageBubble.css-1std3cb {
  background: #cc7a00 !important;
}

.Twilio-ChatItem-Avatar.css-1bqj7vz {
  background: none !important;
}
